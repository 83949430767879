import { defineStore } from 'pinia'

type User = {
    user: {
        id: number
        first_name: string
        last_name: string
        email: string
        profile: {
            area_code: string
            company_id: number
            country_id: number
            created_at: string
            deleted_at: string | null
            id: number
            phone_number: string
            updated_at: string
            user_id: number
        }
    }
}

type Token = {
    token: string
}

type Credentials = {
    email: string
    sessionId: string
}

export const useConsumerAuthStore = defineStore('consumerAuth', () => {
    const user = ref<User | null>(null)
    const token = ref<Token | null>(null)
    const dataPackage = ref<any | null>(null)
    const isLoggedIn = computed(() => !!user.value)

    // Track the current time of the player
    const playerCurrentTime = ref(0)

    // Track the user's experience path order
    const experiencePathOrder = ref(0)

    // Track the time lest in the current experience path
    const experienceTimeLeft = ref(0)

    // Track the end time of the current experience path
    const experienceEndTime = ref(0)

    // Track the current URL of the HLS stream
    const currentUrlHls = ref('')

    // Track when to retrieve the scorecard
    const getScorecard = ref(false)

    // Track if the experience has ended
    const isEnded = ref(false)

    // Track when to leave the meeting
    const leaveMeeting = ref(false)

    // Track when to join the breakout room
    const joinBreakoutRoom = ref(false)

    // Add new state for video muting
    const shouldMuteVideo = ref(false)

    // Fetch the user data
    async function fetchUser() {
        // Get the token from the local storage
        const token = localStorage.getItem('token')

        if (!token) {
            return
        }

        const data = await useApiFetch('/api/user', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        user.value = data as User
    }

    // Log the user in
    async function login(credentials: Credentials) {
        await useApiFetch('/sanctum/csrf-cookie')

        const login = await useApiFetch('/api/exp/auth', {
            params: {
                email: credentials.email,
                sessionId: credentials.sessionId,
            }
        }) as Token

        // Store the token in the local storage
        if (login.token) {
            localStorage.setItem('token', login.token)
        }

        // Fetch the user data
        await fetchUser()

        return login
    }

    // Get the data package
    async function fetchDataPackage(sessionId: number) {
        try {
            const token = localStorage.getItem('token')
            const data = await useApiFetch('/api/consumer/getdatapackage', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },params: {
                    sessionId: sessionId
                }
            })
            dataPackage.value = data
        } catch (error) {
            console.error('Failed to get data:', error)
        }
    }

    // Clear the user data
    function clear() {
        user.value = null
        localStorage.removeItem('token')
    }

    return { user, login, isLoggedIn, fetchUser, fetchDataPackage, dataPackage, token, clear, playerCurrentTime, experiencePathOrder, experienceTimeLeft, experienceEndTime, currentUrlHls, getScorecard, isEnded, leaveMeeting, joinBreakoutRoom, shouldMuteVideo }
})
